let _root;

export default class initHeader {
    constructor(root) {
        this.init();
    }

    init() {
        _root = this;

        window.addEventListener('scroll', function(e) {
            _root.headerHeight();
        });

        _root.headerHeight();

    }

    headerHeight() {

        let _stickyValue = 100;
        //let _stickyValue = (window.screen.height * 0.5);

        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        const _menuToggle = document.querySelector('#menu-toggle');
        if ('undefined' != typeof _menuToggle && null != _menuToggle) {
            _stickyValue < scrollTop ? _menuToggle.classList.add('active') : _menuToggle.classList.remove('active');
        }

    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}